import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./Navbar.css";
import Home from "../components/pages/Home";
import About from "../components/pages/About";
import Projects from "../components/pages/Projects";
import Resume from "../components/pages/Resume";

function Navbar() {
	const [click, setClick] = useState(false);

	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	return (
		<Router>
			<nav className="navbar">
				<div className="navbar-container">
					<div className="menu-icon" onClick={handleClick}>
						<i className={click ? "fas fa-times" : "fas fa-bars"} />
					</div>
					<ul className={click ? "nav-menu active" : "nav-menu"}>
						<li className="nav-item">
							<Link to="/" className="nav-links" onClick={closeMobileMenu}>
								Home
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/about" className="nav-links" onClick={closeMobileMenu}>
								About
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/projects" className="nav-links" onClick={closeMobileMenu}>
								Projects
							</Link>
						</li>

						<li className="nav-item">
							<Link to="/resume" className="nav-links" onClick={closeMobileMenu}>
								Contact
							</Link>
						</li>
					</ul>
				</div>
			</nav>
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/about" exact component={About} />
				<Route path="/projects" exact component={Projects} />
				<Route path="/resume" exact component={Resume} />
			</Switch>
		</Router>
	);
}

export default Navbar;
