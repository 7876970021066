import React from "react";
import "../../App.css";
import Powerslap from "../video/video-2.mp4";
import picture from "../images/my-pic.jpg";
import { Document, Page } from "react-pdf";

export default function About() {
	return (
		<div className="hero-container">
			<video className="video-container" autoPlay loop muted>
				<source src={Powerslap} type="video/mp4" />
			</video>
			<div className="container">
				<img className="image" src={picture} />
				<div className="about">
					Hello! My name is Anisha and I am a passionate Software Developer with
					experience in building web applications using C#, JavaScript, React,
					Python, and Php. I graduated from Minnesota State University Moorhead with
					a double degree in Computer Information Systems and Computer Information
					Technology. My commitment to high quality and standards has helped me to be
					successful in the roles I have held and I will focus on bringing good
					coding practice to the team to tackle challenges in a way that is modular
					and reusable for future efforts.
				</div>
			</div>
		</div>
	);
}
