import React from "react";
import "../App.css";
import { Button } from "./Buttons";
import "./HeroSection.css";
import Powerslap from "./video/video-3.mp4";

function HeroSection() {
	return (
		<div className="hero-container">
			<video className="video-container" autoPlay="true" loop="true" muted="true">
				<source src={Powerslap} type="video/mp4" />
			</video>
			<h1>
				Hello, I am Anisha Shrestha! <br></br> Welcome to my portfolio
			</h1>
		</div>
	);
}

export default HeroSection;
