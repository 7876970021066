import React from "react";
import "../../App.css";
import Powerslap from "../video/video-6.mp4";
import { FaGithub } from "react-icons/fa";

function Resume() {
	return (
		<div className="hero-container">
			<video className="video-container" autoPlay loop muted>
				<source src={Powerslap} type="video/mp4" />
			</video>
			<div className="resume">
				<h1>
					Get in touch
					<br></br>
					Email: shrestan314@gmail.com
				</h1>
			</div>
		</div>
	);
}
export default Resume;
