import React from "react";
import "../../App.css";
import CardItem from "../CardItem";
import "../Cards.css";
import Powerslap from "../video/video-5.mp4";
function Projects() {
	return (
		<div className="hero-container">
			<video className="video-container" autoPlay loop muted>
				<source src={Powerslap} type="video/mp4" />
			</video>
			<div className="projects">
				<div className="cards">
					<h1 className="title">Uploading soon. Thank you for your patience.</h1>
					<div className="cards__container">
						<div className="cards__wrapper">
							<ul className="cards__items">
								<CardItem
									src="images/img-9.jpg"
									text="This is my first project"
									label="Project No.1"
									path="/projects"
								/>
								<CardItem
									src="images/img-3.jpg"
									text="This is my second project"
									label="Project No.2"
									path="/projects"
								/>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Projects;
